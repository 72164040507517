<template>
<div>
    <section class="login_page">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-sm-8 col-md-6 col-lg-5 col-xl-4 login_box">
                    <!-- login_img  -->
                    <div class="login_img">
                        <img src="../assets/images/login_img.png" class="img-fluid" alt="img" />
                        <h3 class="m-0 text-center my-3 fw-bolder">Welcome to HUB</h3>
                    </div>
                    <div class="login_form">
                        <form action="" @submit.prevent="submit">
                            <!-- email  -->
                            <div class="email_box mb-2">
                                <label for="">Email</label>
                                <input type="email" class="form-control shadow-none" v-model="state.email" placeholder="Enter the email" autocomplete="off" />
                                <span class="error" v-if="v$.email.$invalid && submitted">{{ this.v$.email.$silentErrors[0].$message }}</span>
                            </div>
                            <!-- Password  -->
                            <div class="password_box mb-2 position-relative">
                                <label for="">Password</label>
                                <input :type="type" class="form-control shadow-none" v-model="state.password" placeholder="Enter the password" autocomplete="off" />
                                <span class="error" v-if="v$.password.$invalid && submitted">{{ this.v$.password.$silentErrors[0].$message }}</span>
                                <div class="hide_show">
                                    <!-- Show button  -->
                                    <button type="button" v-if="type == 'text'" class="btn p-0 shadow-none" @click="type = 'password'"> <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" style="fill:var(--db-text-grey);">
                                            <path d="M12 9a3.02 3.02 0 0 0-3 3c0 1.642 1.358 3 3 3 1.641 0 3-1.358 3-3 0-1.641-1.359-3-3-3z"></path>
                                            <path d="M12 5c-7.633 0-9.927 6.617-9.948 6.684L1.946 12l.105.316C2.073 12.383 4.367 19 12 19s9.927-6.617 9.948-6.684l.106-.316-.105-.316C21.927 11.617 19.633 5 12 5zm0 12c-5.351 0-7.424-3.846-7.926-5C4.578 10.842 6.652 7 12 7c5.351 0 7.424 3.846 7.926 5-.504 1.158-2.578 5-7.926 5z"></path>
                                        </svg></button>
                                    <!-- Hide button  -->
                                    <button type="button" v-else class="btn p-0 shadow-none" @click="type = 'text'"> <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" style="fill: var(--db-text-grey);">
                                            <path d="M12 19c.946 0 1.81-.103 2.598-.281l-1.757-1.757c-.273.021-.55.038-.841.038-5.351 0-7.424-3.846-7.926-5a8.642 8.642 0 0 1 1.508-2.297L4.184 8.305c-1.538 1.667-2.121 3.346-2.132 3.379a.994.994 0 0 0 0 .633C2.073 12.383 4.367 19 12 19zm0-14c-1.837 0-3.346.396-4.604.981L3.707 2.293 2.293 3.707l18 18 1.414-1.414-3.319-3.319c2.614-1.951 3.547-4.615 3.561-4.657a.994.994 0 0 0 0-.633C21.927 11.617 19.633 5 12 5zm4.972 10.558-2.28-2.28c.19-.39.308-.819.308-1.278 0-1.641-1.359-3-3-3-.459 0-.888.118-1.277.309L8.915 7.501A9.26 9.26 0 0 1 12 7c5.351 0 7.424 3.846 7.926 5-.302.692-1.166 2.342-2.954 3.558z"></path>
                                        </svg></button>
                                </div>
                            </div>
                            <!-- Forget Password  -->
                            <div class="forget_password mb-4 text-end">
                                <router-link to="/forgot">Forget Password ?</router-link>
                            </div>
                            <!-- Button  -->
                            <div class="email_box text-center">
                                <button v-if="loading" type="submit" class="btn edit_btn shadow-none w-100">Login</button>
                                <button v-else class="btn edit_btn shadow-none w-100" disabled>
                                    <div class="spinner-border spinner-border-sm text-light" role="status">
                                        <span class="visually-hidden">Loading...</span>
                                    </div>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>
</template>

<script>
import {
    useStore
} from 'vuex'
import router from "@/router"

import ApiClass from "@/api/api";
import useVuelidate from "@vuelidate/core";
import {
    required,
    email,
    minLength,
    helpers
} from "@vuelidate/validators";
import {
    reactive,
    computed,
    ref
} from "@vue/reactivity";
import Swal from "sweetalert2/dist/sweetalert2.js";

import "sweetalert2/src/sweetalert2.scss";
export default {
    name: "home-view",
    setup() {
        const store = useStore();
        var submitted = ref(false);
        var loading = ref(true);
        var type = ref("password");
        const state = reactive({
            email: "",
            password: "",
        });

        const rules = computed(() => {
            return {
                email: {
                    required: helpers.withMessage("Email is required", required),
                    email: helpers.withMessage("Not a valid Mail Address", email),
                },
                password: {
                    required: helpers.withMessage("Password is required", required),
                    minLength: minLength(6),
                },
            };
        });
        const v$ = useVuelidate(rules, state);

        async function submit() {
            this.submitted = true;

            if (this.v$.$invalid) {
                return;
            }

            this.loading = false;
            let response = await ApiClass.postRequest("api/user/login", this.state, false);

            if (response.data.status_code == 1) {
                let user = response.data.data.user
                console.log({user})



                store.commit("setuserData", response.data.data.user);
                localStorage.setItem("token", response.data.data.token);

               (user.type_id== "admin") ? "" : localStorage.setItem("metaDatA",btoa(JSON.stringify( response.data.data.user.isLeader)))                 // localStorage.setItem("Time", Date.now())

                Swal.fire({
                    toast: true,
                    position: "top-end",
                    icon: "success",
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 1500,
                });



               user.type_id== "admin" ? router.push("/dashboard")  :  router.push("/team")  ;

                this.loading = true;
            } else {
                Swal.fire({
                    toast: true,
                    position: "top-end",
                    icon: "error",
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 1500,
                });
                this.loading = true;
            }
            
            // console.log(response);
        }

        return {
            submit,
            state,
            v$,
            submitted,
            type,
            loading,
        };
    },
};
</script>

<style scoped>
/*************************** Login Page ***************************/

section.login_page {
    background-color: var(--db-dark-blue);
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--db-white);
}

.login_box {
    background: var(--db-slot-dark);
    box-shadow: var(--db-shadow-slot);
    border-radius: 8px;
    padding: 25px;
}

label {
    font-size: 14px;
    color: var(--db-white);
    margin-bottom: 5px;
}

input,
input:focus {
    background: rgba(255, 255, 255, 0.1);
    box-shadow: inset 5px -6px 4px rgba(123, 119, 119, 0.09), inset -4px 3px 5px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    border: 0;
    color: rgba(255, 255, 255, 0.8);
    font-family: var(--db-mons-light);
}

input::placeholder {
    color: rgba(255, 255, 255, 0.8);
    font-family: var(--db-mons-light);
}

.edit_btn {
    background: var(--db-gradient-btn);
    box-shadow: var(--db-shadow-btn);
    border-radius: 4px;
    color: var(--db-white);
}

.edit_btn:hover {
    cursor: pointer;
    color: var(--db-white);
}

.forget_password a {
    font-family: var(--db-mons-regular);
    font-size: 14px;
    color: var(--db-text-grey);
    text-decoration: none;
}

.forget_password a:hover {
    text-decoration: underline;
}

h3 {
    color: rgba(255, 255, 255, 0.8);
    text-transform: uppercase;
    text-shadow: 1px 1px 0px var(--db-text-grey), 2px 2px 0px var(--db-text-grey), 3px 3px 0px var(--db-text-grey);
}

.hide_show {
    position: absolute;
    right: 5px;
    top: 33px;
}

.error {
    color: red;
    /* font-weight: bold; */
}
</style>
