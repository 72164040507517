import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate';
export default createStore({
    plugins: [createPersistedState()],
    state: {
        userData: {},
    },
    getters: {
        getuserData: state => {
            return state.userData;
        },
    },
    mutations: {
        setuserData(state, value) {
            state.userData = value;
        },
    },
    actions: {},
    modules: {}
})