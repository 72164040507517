import axios from "axios";

export default class ApiClass {

    // static baseUrl = 'http://192.168.1.22:8001/';
    static baseUrl = `${window.location.href}backend/public/`;

    //******************************* Post api *******************************************//


    // static async postRequest(apiUrl, formData, isToken) {
    //         var header = await this.config(isToken)
    //         return axios.post(this.baseUrl + apiUrl, formData, { headers: header }).then(result => {
    //             return result;
    //         }).catch(error => {
    //             if (error.response.status == 401) {
    //                 this.unauthenticateRedirect();
    //             }
    //         });
    //     }
    static postRequest(apiUrl, formData = null, isToken = true, headers = null) {
        return axios.post(this.baseUrl + apiUrl, formData, this.config(isToken, headers)).then(result => {
            return result;
        }).catch(error => {
            if (error.response.status == 401) {
                this.unauthenticateRedirect();
            }
        });
    }


    //******************************* Get api *******************************************//

    static async getRequest(apiUrl, isToken = true, headers = null) {

        return axios.get(this.baseUrl + apiUrl, this.config(isToken, headers)).then(result => {
            return result;
        }).catch(error => {
            if (error.response.status == 401) {
                this.unauthenticateRedirect();
            }
        });
    }

    //******************************* Put api *******************************************//


    static async putRequest(apiUrl, id, formData, isToken = true, headers = null) {

            return axios.put(this.baseUrl + apiUrl + id, formData, this.config(isToken, headers)).then(result => {
                return result;
            }).catch(error => {
                if (error.response.status == 401) {
                    this.unauthenticateRedirect();
                }
            });
        }


        // put without route praman
    static async updateRequest(apiUrl,  formData, isToken = true, headers = null) {

            return axios.put(this.baseUrl + apiUrl ,  formData, this.config(isToken, headers)).then(result => {
                return result;
            }).catch(error => {
                if (error.response.status == 401) {
                    this.unauthenticateRedirect();
                }
            });
        }
        //******************************* delete api *******************************************//

    static async deleteRequest(apiUrl, isToken = true, headers = null) {

            return axios.delete(this.baseUrl + apiUrl, this.config(isToken, headers)).then(response => response)
                .catch(error => {
                    if (error.response.status == 401) {
                        this.unauthenticateRedirect();
                    }
                });
        }
        //******************************* Configrations of header and parameters ******************************* */

    // static config(isToken = true) {

    //     if (isToken) {

    //         return { 'Authorization': "Bearer " + localStorage.getItem("token") }

    //     }
    //     return { 'Access-Control-Allow-Origin': '*' }

    // }
    static config(isToken = true, headers = null) {
        var defaultHeaders = {
            Accept: "application/json"
        };
        var merge = {};
        if (isToken) {
            var token = { Authorization: "Bearer " + localStorage.getItem("token") }

            merge = Object.assign(defaultHeaders, token)
        }
        if (headers != null) {
            merge = Object.assign(defaultHeaders, headers)
        }

        return {
            headers: merge
        }
    }

    //********************************* If the unautherntication Error..... ************************************** */

    static unauthenticateRedirect() {
        localStorage.clear();   
        location.replace('/');
    }
}